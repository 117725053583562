import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export default function App() {
  const [darkMode, setDarkMode] = useState(true) // Force dark mode
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  // Handle scroll effects
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 20)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Always keep dark mode enabled
  useEffect(() => {
    document.documentElement.classList.add('dark')
  }, [])

  const FadeInSection = ({ children }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    })

    useEffect(() => {
      if (inView) {
        controls.start('visible')
      }
    }, [controls, inView])

    return (
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 }
        }}
      >
        {children}
      </motion.div>
    )
  }

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [mobileMenuOpen])

  return (
    <div className="min-h-screen font-sans antialiased bg-zinc-900 text-zinc-100">
      {/* Full-screen glass overlay for mobile menu */}
      <div
        className={`fixed inset-0 bg-black/20 backdrop-blur-xl transition-all duration-500 z-40
          ${mobileMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setMobileMenuOpen(false)}
      />

      <header className={`fixed w-full z-50 transition-all duration-300 ${scrolled || mobileMenuOpen ? 'bg-zinc-900/80 backdrop-blur-lg shadow-sm' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <div className="flex items-center">
              <img
                src="/logov3.png"
                alt="Logo"
                className="h-10 w-auto"
              />
              <span className="text-lg font-semibold text-white">ebys</span>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-8">
              {['Home', 'Services', 'Products', 'About'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="text-zinc-300 hover:text-cyan-400 font-medium tracking-wide transition-colors relative group"
                >
                  {item}
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-cyan-400 transition-all duration-300 group-hover:w-full"></span>
                </a>
              ))}
            </nav>

            {/* Get Started button and Mobile Menu Toggle */}
            <div className="flex items-center gap-6">
              <a
                href="#contact"
                className="hidden md:inline-flex items-center px-6 py-2.5 rounded-full font-semibold text-black bg-white hover:opacity-90 transition-opacity"
              >
                Get Started
              </a>
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="md:hidden p-2 text-zinc-300 rounded-lg transition-colors relative z-50"
                aria-label="Toggle menu"
              >
                <div className="relative w-6 h-6">
                  <span className={`absolute left-0 block w-6 h-0.5 bg-current transform transition-all duration-300 ${mobileMenuOpen ? 'rotate-45 top-3' : 'top-2'}`} />
                  <span className={`absolute left-0 block w-6 h-0.5 bg-current transform transition-all duration-300 ${mobileMenuOpen ? '-rotate-45 top-3' : 'top-4'}`} />
                </div>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed inset-x-0 top-0 h-screen transition-all duration-500 ease-in-out ${mobileMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'} bg-zinc-900/95 backdrop-blur-xl pt-28 z-40`}
        >
          <nav className="container mx-auto px-4 py-6 flex flex-col items-center space-y-8">
            {['Home', 'Services', 'Products', 'About', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="relative group py-2 text-zinc-300 hover:text-cyan-400 font-medium tracking-wide transition-colors w-fit text-xl"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item}
                <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-cyan-400 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
              </a>
            ))}
            {/* Mobile-only CTA button */}
            <a
              href="#contact"
              className="mt-8 inline-flex items-center px-8 py-3 rounded-full font-semibold text-black bg-white hover:opacity-90 transition-opacity text-lg"
              onClick={() => setMobileMenuOpen(false)}
            >
              Get Started
            </a>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section id="home" className="relative min-h-screen flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-zinc-800">
          <div className="absolute inset-0 opacity-30" style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}></div>
        </div>
        <div className="relative container mx-auto px-4 py-32 text-center text-white">
          <FadeInSection>
            <div className="max-w-4xl mx-auto">
              <h1 className="text-5xl md:text-7xl font-extrabold tracking-tight mb-8 [text-wrap:balance]">
                Innovate Your Digital Presence
              </h1>
              <p className="text-xl md:text-2xl font-medium mb-12 opacity-90 max-w-2xl mx-auto [text-wrap:balance]">
                Crafting exceptional digital experiences with AI-powered development solutions
              </p>
              <div className="flex flex-col sm:flex-row gap-6 justify-center">
                <a
                  href="#contact"
                  className="inline-flex items-center px-8 py-4 rounded-full bg-white text-black font-semibold hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                >
                  Start Your Journey
                </a>
                <a
                  href="#services"
                  className="inline-flex items-center px-8 py-4 rounded-full bg-zinc-500 text-white font-semibold backdrop-blur-sm hover:bg-blue-700/40 transition-all duration-300 transform hover:scale-105"
                >
                  Explore Services
                </a>
              </div>
            </div>
          </FadeInSection>
        </div>
        <div className="absolute bottom-0 left-0 right-0">
          <svg viewBox="0 0 1440 200" xmlns="http://www.w3.org/2000/svg" className="fill-zinc-900">
            <path d="M0,160L48,144C96,128,192,96,288,90.7C384,85,480,107,576,128C672,149,768,171,864,165.3C960,160,1056,128,1152,117.3C1248,107,1344,117,1392,122.7L1440,128L1440,200L1392,200C1344,200,1248,200,1152,200C1056,200,960,200,864,200C768,200,672,200,576,200C480,200,384,200,288,200C192,200,96,200,48,200L0,200Z" />
          </svg>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-32 bg-zinc-900">
        <div className="container mx-auto px-4">
          <FadeInSection>
            <div className="max-w-3xl mx-auto text-center mb-20">
              <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6">Our Services</h2>
              <p className="text-lg text-zinc-400">
                Transforming ideas into exceptional digital solutions
              </p>
            </div>
          </FadeInSection>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: "🌐",
                title: "AI Development",
                description: "Cutting-edge AI solutions that power modern web applications and digital experiences."
              },
              {
                icon: "💡",
                title: "Digital Products",
                description: "Innovative digital products that solve real-world problems and drive business growth."
              },
              {
                icon: "🚀",
                title: "Startup Solutions",
                description: "Tailored solutions to help startups scale and succeed in the digital landscape."
              }
            ].map((service, index) => (
              <FadeInSection key={index}>
                <div className="group relative p-8 rounded-2xl bg-zinc-800/50 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                  <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-cyan-500/5 to-blue-600/5 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative">
                    <div className="w-16 h-16 mb-6 rounded-2xl bg-gradient-to-br from-cyan-500 to-blue-600 flex items-center justify-center text-white text-2xl">
                      {service.icon}
                    </div>
                    <h3 className="text-xl font-bold mb-4">{service.title}</h3>
                    <p className="text-zinc-400 mb-6">{service.description}</p>
                    <a
                      href="#contact"
                      className="inline-flex items-center text-cyan-400 group-hover:text-cyan-300 font-medium transition-colors"
                    >
                      Learn More <span className="ml-2 transition-transform group-hover:translate-x-1">→</span>
                    </a>
                  </div>
                </div>
              </FadeInSection>
            ))}
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="py-32 bg-zinc-800/50">
        <div className="container mx-auto px-4">
          <FadeInSection>
            <div className="max-w-3xl mx-auto text-center mb-20">
              <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6">Our Products</h2>
              <p className="text-lg text-zinc-400">
                Discover our suite of innovative digital solutions
              </p>
            </div>
          </FadeInSection>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "AI Assistant",
                description: "Intelligent virtual assistant for enhanced customer support and engagement.",
                image: "/placeholder.svg?height=400&width=600"
              },
              {
                title: "Analytics Platform",
                description: "Comprehensive data visualization and analytics solution for businesses.",
                image: "/placeholder.svg?height=400&width=600"
              },
              {
                title: "E-commerce Suite",
                description: "Complete e-commerce solution with advanced features and integrations.",
                image: "/placeholder.svg?height=400&width=600"
              }
            ].map((product, index) => (
              <FadeInSection key={index}>
                <div className="group bg-zinc-900 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="aspect-video relative overflow-hidden">
                    <img
                      src={product.image}
                      alt={product.title}
                      className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                    />
                  </div>
                  <div className="p-8">
                    <h3 className="text-xl font-bold mb-3">{product.title}</h3>
                    <p className="text-zinc-400 mb-6">{product.description}</p>
                    <a
                      href="#"
                      className="inline-flex items-center text-cyan-400 hover:text-cyan-300 font-medium transition-colors"
                    >
                      View Details <span className="ml-2">→</span>
                    </a>
                  </div>
                </div>
              </FadeInSection>
            ))}
          </div>
        
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-32 bg-zinc-900">
        <div className="container mx-auto px-4">
          <FadeInSection>
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-16">
                <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6">Start Your Project</h2>
                <p className="text-lg text-zinc-400">
                  Let's create something extraordinary together
                </p>
              </div>
              <div className="bg-zinc-800/50 rounded-2xl shadow-xl p-8 md:p-12">
                <form className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-zinc-300 mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="w-full px-4 py-3 rounded-lg bg-zinc-900 border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-zinc-300 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full px-4 py-3 rounded-lg bg-zinc-900 border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-zinc-300 mb-2">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      className="w-full px-4 py-3 rounded-lg bg-zinc-900 border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors"
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-8 py-4 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-600 text-white font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-[1.02]"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </FadeInSection>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-zinc-900 text-zinc-100 py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
            <div>
              <div className="flex items-center">
                <img
                  src="/logov3.png"
                  alt="Logo"
                  className="h-10 w-auto"
                />
                <span className="text-lg font-semibold text-white">ebys</span>
              </div>
              <p className="mt-4 text-zinc-400">
                Empowering businesses with AI-driven web solutions and digital innovation.
              </p>
            </div>
            {[
              {
                title: "Services",
                links: ["AI Development", "Digital Products", "Startup Solutions", "Consulting"]
              },
              {
                title: "Company",
                links: ["About Us", "Careers", "Blog", "Contact"]
              },
              {
                title: "Connect",
                links: ["Twitter", "LinkedIn", "GitHub", "Discord"]
              }
            ].map((column, index) => (
              <div key={index}>
                <h3 className="text-lg font-semibold mb-4">{column.title}</h3>
                <ul className="space-y-2">
                  {column.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a
                        href="#"
                        className="text-zinc-400 hover:text-cyan-400 transition-colors"
                      >
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="border-t border-zinc-800 pt-8 text-center text-zinc-400">
            <p>&copy; {new Date().getFullYear()} webys.org. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}